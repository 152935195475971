<template>
  <div class="history-planning">
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div v-else class="content-tab-history-planning-poseur mt-2">
      <v-row>
        <v-col cols="11">
          <div
            v-if="getHistoryPlanningPoseurLoading"
            class="color-crm font-sz-12 font-text"
          >
            {{ $t('loading') }}
          </div>
          <div v-if="getHistoryPlanningPoseurError" class="error-msg">
            <ul v-if="Array.isArray(getHistoryPlanningPoseurError)">
              <li
                v-for="(e, index) in getHistoryPlanningPoseurError"
                :key="index"
              >
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getHistoryPlanningPoseurError }}</span>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            origin="center center"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openPopover"
                small
                v-bind="attrs"
                v-on="on"
                class="btn-filter small-btn"
                :class="{
                  dark:
                    date != null || selected != null || poseur != null
                      ? true
                      : false
                }"
                dark
                color="#5C2DD3"
                :title="
                  $i18n.locale === 'fr' ? 'Choisir un filtre' : 'Choose filter'
                "
              >
                <v-icon class="font-sz-16">mdi-filter</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-divider class="line-hr-filter"></v-divider>
              <v-card-text class="body-card filter-content-popup">
                <v-form>
                  <v-autocomplete
                    :clearable="true"
                    color="#5C2DD3"
                    :placeholder="$t('searchMsg', { msg: 'Poseur' })"
                    item-color="#5C2DD3"
                    v-model="poseur"
                    :items="[{ text: '', value: 'vide' }, ...options]"
                    :loading="getProjectsUsersTechniciensLoading"
                    :persistent-placeholder="true"
                    return-object
                    label="Poseur"
                    item-text="text"
                    dense
                    item-value="value"
                    :no-data-text="$t('noDataOption')"
                    outlined
                    :menu-props="{
                      bottom: true,
                      offsetY: true
                    }"
                  >
                  </v-autocomplete>
                  <v-menu :close-on-content-click="false" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Date"
                        outlined
                        readonly
                        clearable
                        dense
                        v-on="on"
                        v-bind="attrs"
                        :persistent-placeholder="true"
                        :placeholder="$t('msgSelectDate')"
                        prepend-inner-icon="mdi-calendar"
                        color="#5C2DD3"
                      ></v-text-field>
                    </template>

                    <v-date-picker
                      v-model="date"
                      no-title
                      locale="fr"
                      color="#5C2DD3"
                      header-color="#5C2DD3"
                      event-color="704ad1"
                    >
                    </v-date-picker>
                  </v-menu>
                  <v-autocomplete
                    :clearable="true"
                    color="#5C2DD3"
                    :placeholder="
                      $i18n.locale === 'fr'
                        ? 'List de modification'
                        : 'List of modifications'
                    "
                    item-color="#5C2DD3"
                    v-model="selected"
                    :items="computedModificationList"
                    :persistent-placeholder="true"
                    return-object
                    label="Modification"
                    item-text="name"
                    dense
                    item-value="value"
                    :no-data-text="$t('noDataOption')"
                    outlined
                    :menu-props="{
                      bottom: true,
                      offsetY: true
                    }"
                  >
                  </v-autocomplete>
                </v-form>
              </v-card-text>
              <v-card-actions class="popover-action-filter">
                <v-btn
                  class="ma-2 capitalize-text"
                  dark
                  color="#5C2DD3"
                  @click="filter"
                >
                  {{ $t('btnValider') }}
                </v-btn>
                <v-btn
                  class="ma-2 ml-2 capitalize-text"
                  text
                  @click="cancelPopover"
                >
                  {{ $t('btnCancel') }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
      <div class="table-users mt-2">
        <v-data-table
          v-model="selectedTable"
          :headers="$i18n.locale == 'fr' ? fields : fieldsEn"
          :page.sync="pagination.page"
          :items-per-page="pagination.itemsPerPage"
          :server-items-length="countPaginationPlanningPoseur"
          @update:page="handlePaginationChange"
          @update:items-per-page="changePerPage"
          :footer-props="{
            'items-per-page-options': perPageList
          }"
          :items="getListHistoryPlanningPoseur"
          class="table-users"
          :calculate-widths="false"
          :fixed-header="true"
          hide-action
          :no-data-text="
            getHistoryPlanningPoseurLoading
              ? $i18n.locale == 'fr'
                ? 'Chargement... Veuillez patienter'
                : 'Loading... Please wait'
              : $i18n.locale == 'fr'
              ? `Il n'y a aucune donnée à afficher dans ce tableau.`
              : 'There is no data to display in this table.'
          "
        >
          <!-- BODY TABLE -->

          <template v-slot:[`item.nom`]="{ item }">
            <td class="custom-cell">
              {{ item.user.full_name }}
            </td>
          </template>
          <template v-slot:[`item.key`]="{ item }">
            <td class="custom-cell">
              {{ item.key }}
            </td>
          </template>

          <template v-slot:[`item.precedent`]="{ item }">
            <td class="custom-cell">
              {{ item.previous }}
            </td>
          </template>
          <template v-slot:[`item.current`]="{ item }">
            <td class="custom-cell">
              {{ item.current }}
            </td>
          </template>
          <template v-slot:[`item.date_modif`]="{ item }">
            <td class="custom-cell">
              {{ item.timestamp | date }}
            </td>
          </template>
        </v-data-table>
      </div>
      <!-- <div class="footer-style-table mt-2">
        <div class="text-center pagination-table">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="pagination"
            :total-visible="7"
            color="#5C2DD3"
            item-color="#5C2DD3"
            circle
          ></v-pagination>
        </div>
        <div class="select-input-vuetify">
          <v-select
            class="per-page-element-style"
            v-model="per_page"
            :items="perPageList"
            @change="changePerPage"
            label="Eléments par page"
            outlined
            dense
            hide-details
            no-data-text="Aucun élément trouvé"
            :menu-props="{
              left: true,
              offsetX: true
            }"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-select>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import * as moment from 'moment'
moment.locale('fr')
export default {
  data() {
    return {
      menu: false,
      selectedTable: [],
      perPageList: [10, 20, 30, 50],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItems: this.countPaginationPlanningPoseur
      },
      poseur: null,
      date: null,
      fields: [
        {
          value: 'nom',
          text: 'Utilisateur',
          thClass: 'width-th-nom',
          tdClass: 'width-th-nom',
          sortable: false
        },
        {
          value: 'key',
          text: 'Modification',
          thClass: 'width-th-key',
          tdClass: 'width-th-key',
          sortable: false
        },
        {
          value: 'precedent',
          text: 'Avant',
          thClass: 'width-th-precedent',
          tdClass: 'width-th-precedent',
          sortable: false
        },
        {
          value: 'current',
          text: 'Après',
          thClass: 'width-th-current',
          tdClass: 'width-th-current',
          sortable: false
        },
        {
          value: 'date_modif',
          text: 'Date de Modification',
          thClass: 'width-th-date',
          tdClass: 'width-th-date',
          sortable: false
        }
      ],
      fieldsEn: [
        {
          value: 'nom',
          text: 'Users',
          thClass: 'width-th-nom',
          tdClass: 'width-th-nom',
          sortable: false
        },
        {
          value: 'key',
          text: 'Modification',
          thClass: 'width-th-key',
          tdClass: 'width-th-key',
          sortable: false
        },
        {
          value: 'precedent',
          text: 'Before',
          thClass: 'width-th-precedent',
          tdClass: 'width-th-precedent',
          sortable: false
        },
        {
          value: 'current',
          text: 'After',
          thClass: 'width-th-current',
          tdClass: 'width-th-current',
          sortable: false
        },
        {
          value: 'date_modif',
          text: 'Modification date',
          thClass: 'width-th-date',
          tdClass: 'width-th-date',
          sortable: false
        }
      ],
      page: 1,
      per_page: 10,
      selected: null,
      optimised: 0,
      modificationList: [
        {
          name: 'Optimisation',
          value: 0,
          $isDisabled: false
        },
        {
          name: 'Ligne 1',
          value: 1,
          $isDisabled: false
        },
        {
          name: 'Ligne 2',
          value: 2,
          $isDisabled: false
        },
        {
          name: 'Ligne 3',
          value: 3,
          $isDisabled: false
        },
        {
          name: 'Ligne 4',
          value: 4,
          $isDisabled: false
        },
        {
          name: 'Ligne 5',
          value: 5,
          $isDisabled: false
        }
      ],
      initLoading: false
    }
  },
  methods: {
    ...mapActions([
      'fetchUsersTechniciens',
      'fetchListHistoryPlanningPoseur',
      'resetHistoryPlanningPoseur'
    ]),
    changePerPage(newPerPage) {
      this.pagination.itemsPerPage = newPerPage
      this.pagination.page = 1
      this.per_page = newPerPage
      this.fetchListHistoryPlanningPoseur({
        page: this.page,
        per_page: this.per_page,
        optimised: this.selected && this.selected.value === 0 ? 1 : 0
      })
    },
    handlePaginationChange(pagination) {
      this.page = pagination
      this.pagination.page = pagination
      this.fetchListHistoryPlanningPoseur({
        page: this.page,
        per_page: this.per_page,
        optimised: this.selected && this.selected.value === 0 ? 1 : 0,
        technician_id: this.poseur,
        row_index:
          this.selected && this.selected.value > 0 ? this.selected.value : null,
        day: this.date
      })
      this.resetHistoryPlanningPoseur()
    },
    openPopover() {
      this.menu = true
    },
    cancelPopover() {
      this.menu = false
      this.fetchListHistoryPlanningPoseur({
        page: this.page,
        per_page: this.per_page,
        optimised: this.selected && this.selected.value === 0 ? 1 : 0
      })
      this.selected = null
      this.poseur = null
      this.date = null
      this.resetHistoryPlanningPoseur()
    },
    async filter() {
      if (this.date || this.selected || this.poseur) {
        this.page = 1
        const response = await this.fetchListHistoryPlanningPoseur({
          page: this.page,
          per_page: this.per_page,
          optimised: this.selected && this.selected.value === 0 ? 1 : 0,
          technician_id: this.poseur,
          row_index:
            this.selected && this.selected.value > 0
              ? this.selected.value
              : null,
          day: this.date
        })
        if (response) {
          this.menu = false
        }
        this.resetHistoryPlanningPoseur()
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsUsersTechniciensLoading',
      'getHistoryPlanningPoseurLoading',
      'getProjectsUsersTechniciens',
      'getListHistoryPlanningPoseur',
      'countPaginationPlanningPoseur',
      'getHistoryPlanningPoseurError'
    ]),
    totalPages() {
      if (this.countPaginationPlanningPoseur) {
        return Math.ceil(this.countPaginationPlanningPoseur / this.per_page)
      }
      return this.countPaginationPlanningPoseur
    },
    options: function() {
      if (this.getProjectsUsersTechniciens) {
        return this.getProjectsUsersTechniciens.map(u => {
          return {
            text: u.full_name,
            value: u.id
          }
        })
      }
      return []
    },
    computedModificationList: function() {
      return this.modificationList.map(option => {
        if (option.value === 0) {
          option.$isDisabled =
            this.selected && this.selected.value > 0 ? true : false
        } else {
          option.$isDisabled =
            this.selected && this.selected.value === 0 ? true : false
        }
        return option
      })
    }
  },

  async mounted() {
    this.initLoading = true
    await this.fetchListHistoryPlanningPoseur({
      page: this.page,
      per_page: this.per_page,
      optimised: this.selected && this.selected.value === 0 ? 1 : 0
    })
    this.fetchUsersTechniciens()
    this.resetHistoryPlanningPoseur()
    this.initLoading = false
  },
  filters: {
    date: function(value) {
      if (!value) return ''
      value = moment(value, 'YYYY-MM-DD HH:mm:ss').format(
        'DD MMM YYYY, HH:mm:ss'
      )
      return value
    }
  }
}
</script>

<style lang="scss">
.content-tab-history-planning-poseur {
  .width-th-nom,
  .width-th-key,
  .width-th-precedent,
  .width-th-current,
  .width-th-date {
    height: 39px;
  }

  .table-users {
    table {
      border-collapse: collapse;
    }

    height: 100% !important;
    max-height: 100% !important;
    .header-users {
      th {
        text-align: left;
        padding: 0px 14px;
        height: 40px;
        font-size: 14px;
        white-space: nowrap;
        border-bottom: 1px solid #e0e0e0;
      }
    }
    thead {
      tr {
        th {
          white-space: nowrap;
          color: rgb(0 0 0) !important;
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 12px !important;
          letter-spacing: 0.17px;
          line-height: 17.16px;
          font-family: 'Montserrat', sans-serif;
        }
      }
    }
    .v-data-table__wrapper::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    .v-data-table__wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 4px;
      background-color: rgba(238, 238, 238, 1) !important;
    }
    .v-data-table__wrapper::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(189, 189, 189, 1);
    }
    .v-data-table__wrapper {
      cursor: pointer;
      max-height: calc(100vh - 333px) !important;
      height: calc(100vh - 333px) !important;
      margin-bottom: 0;
      overflow-y: auto;
      overflow-x: auto;
    }
  }
}
.btn-filter {
  &.dark {
    color: #ffffff !important;
    background-color: #3a167f !important;
  }
}
</style>
